window.addEventListener('load', init)

function init(e) {
  console.log('Engines on.')
  AOS.init();

  const SlidersCarrosselProdutos = document.querySelectorAll('.carrossel-produtos-swiper')
  if (SlidersCarrosselProdutos) {
    for (var [i, elSlidersCarrosselProdutos] of SlidersCarrosselProdutos.entries()) {

      var Carousel = new Swiper(elSlidersCarrosselProdutos, {
        slidesPerView: 1.3,
        speed: 400,
        navigation: {
          nextEl: '.swiper-button-next.swiper-button-next-' + i,
          prevEl: '.swiper-button-prev.swiper-button-prev-' + i,
        },
        draggable: true,
        breakpoints: {
          425: {
            slidesPerView: 'auto',
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      });
    }
  }

  if (document.querySelector('.home')) {
    document.querySelector('.home').classList.add('parceiro')
  }

  const SliderCarrosselDepoimentos = document.querySelector('[data-carousel="carrossel-depoimentos"]')
  const WrapperCarrosselDepoimentos = document.querySelector('.parceiro__depoimentos-swiper')
  if (SliderCarrosselDepoimentos) {
    const Carousel = new Swiper(SliderCarrosselDepoimentos, {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      slidesPerView: 1,
      spaceBetween: 25,
      navigation: {
        nextEl: WrapperCarrosselDepoimentos.querySelector('.swiper-button-next'),
        prevEl: WrapperCarrosselDepoimentos.querySelector('.swiper-button-prev'),
      },
    })
  }

  const SliderCarrosselPosts = document.querySelector('[data-carousel="carrossel-posts"]')
  const WrapperCarrosselPosts = document.querySelector('.posts__carousel-swiper')
  if (SliderCarrosselPosts) {
    const Carousel = new Swiper(SliderCarrosselPosts, {
      speed: 400,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 25,
      pagination: {
        el: WrapperCarrosselPosts.querySelector('.swiper-pagination'),
      },
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    })
  }

  /*==MENU ACESSIBILIDADE==*/
  var btA11y = document.querySelector('#a11y .a11y__btn')
  var wrapperA11y = document.getElementById('a11y')
  var btFecharA11y = document.querySelector('#a11y .a11y__close')

  btA11y.addEventListener('click', abrirA11y)
  btFecharA11y.addEventListener('click', fecharA11y)

  function abrirA11y() {
    wrapperA11y.classList.add('active')
  }

  function fecharA11y() {
    wrapperA11y.classList.remove('active')
  }

  sessionStorage.getItem('contrast') && +sessionStorage.getItem('contrast') && document.body.classList.add('has--high-contrast')

  if (document.querySelector('[data-contrast]')) {
    document.querySelector('[data-contrast]').addEventListener(
      'click',
      () => {
        document.body.classList.toggle('has--high-contrast')

        sessionStorage.setItem('contrast', document.body.classList.contains('has--high-contrast') ? 1 : 0)
      },
      true
    )
  }

  if (sessionStorage.getItem('fontsize')) document.body.style.fontSize = sessionStorage.getItem('fontsize') + 'px'

  if (document.querySelector('[data-fontsize-decrease]')) {
    document.querySelector('[data-fontsize-decrease]').addEventListener(
      'click',
      event => {
        event.preventDefault()
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'))

        if (fontsize > 10) {
          document.body.style.fontSize = --fontsize + 'px'
          sessionStorage.setItem('fontsize', fontsize)
        }
      },
      true
    )
  }

  if (document.querySelector('[data-fontsize-increase]')) {
    document.querySelector('[data-fontsize-increase]').addEventListener(
      'click',
      event => {
        event.preventDefault()
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'))

        if (fontsize < 12) {
          document.body.style.fontSize = ++fontsize + 'px'
          sessionStorage.setItem('fontsize', fontsize)
        }
      },
      true
    )
  }
}

/*==animação big numbers==*/

var bigNumbers = document.querySelectorAll('[data-countup-number]')

if (bigNumbers) {

  class countUp {
    constructor(el) {
      this.el = el
      this.setVars()
      this.init()
    }

    setVars() {
      this.number = this.el.querySelectorAll('[data-countup-number]')
      this.observerOptions = { root: null, rootMargin: '0px 0px', threshold: 0 }
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const end = parseFloat(entry.target.dataset.countupNumber.replace(/,/g, ''))
          const decimals = this.countDecimals(end)
          if (entry.isIntersecting) {
            this.iterateValue(entry.target, end, decimals)
          }
        })
      }, this.observerOptions)
    }

    init() {
      if (this.number.length > 0) {
        this.number.forEach(el => {
          this.observer.observe(el)
        })
      }
    }

    iterateValue(el, end, decimals) {
      const start = 0
      const duration = 1500
      let startTimestamp = null

      const step = timestamp => {
        if (!startTimestamp) startTimestamp = timestamp
        const elapsedPercent = (timestamp - startTimestamp) / duration
        const easedProgress = Math.min(this.easeOutQuint(elapsedPercent), 1)
        let interimNumber = Math.abs(easedProgress * (end - start) + start)
        el.innerHTML = this.formatNumber(interimNumber, decimals)
        if (easedProgress < 1) {
          window.requestAnimationFrame(step)
        }
      }

      // requestAnimationFrame returns DOMHighResTimeStamp as a callback (used as timestamp)
      window.requestAnimationFrame(step)
    }

    easeOutQuad(x) {
      return 1 - Math.pow(1 - x, 3)
    }

    easeOutQuint(x) {
      return 1 - Math.pow(1 - x, 5)
    }

    countDecimals(val) {
      if (Math.floor(val) === val) return 0
      return val.toString().split('.')[1].length || 0
    }

    formatNumber(val, decimals) {
      return val.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
    }
  }

  // Simplifed version of Viget dynamic modules to attach instances for this demo
  // https://www.viget.com/articles/how-does-viget-javascript/
  // You CAN use this pattern, but it's single purpose right now
  const dataModules = [...document.querySelectorAll('[data-module="countup"]')]

  dataModules.forEach(element => {
    element.dataset.module.split(' ').forEach(function () {
      new countUp(element)
    })
  })
}

var btMenu = document.getElementById('bt-menu')
var navMenu = document.getElementById('nav-menu')

btMenu.addEventListener('click', toggleMenu)

function toggleMenu() {
  navMenu.classList.toggle('is--active')
}

//BT VOLTAR

var btVoltar = document.querySelector('.bt-voltar')

if (btVoltar) {
  btVoltar.addEventListener('click', navVoltar)

  function navVoltar() {
    history.back()
  }
}
/*==COOKIES==*/

(function () {
  'use strict';

  var cookieAlert = document.getElementById('cookiealert');
  var acceptCookies = document.getElementById('acceptcookies');

  if (!cookieAlert) {
    return;
  }

  cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

  // Show the alert if we cant find the "acceptCookies" cookie
  if (!getCookie('acceptCookies')) {
    cookieAlert.classList.add('active');
  }

  // When clicking on the agree button, create a 1 year
  // cookie to remember user's choice and close the banner
  acceptCookies.addEventListener('click', function () {
    setCookie('acceptCookies', true, 365);
    cookieAlert.classList.remove('active');
  });

  // Cookie functions from w3schools
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
})();

// HOME MARCADA QUANDO EM PARCEIRO OU CONSUMIDOR

const idHome = document.querySelector('.menu-item-21')

if ((document.body.classList.contains('page-id-17')) || (document.body.classList.contains('page-id-19'))) {
  idHome.classList.add('current_page_item')
}

// DETAILS OPEN

const allDetails = document.querySelectorAll('details')
allDetails.forEach(details => {
  details.addEventListener('toggle', (e) => {
    if (details.open) {
      allDetails.forEach((details) => {
        if (details != e.target && details.open) {
          details.open = false
        }
      })
    }
  })
})

// FILTRO PRODUTOS

const filtrosProdutos = document.querySelectorAll('.produtos__topo-filtro')
const tipoMarcas = document.querySelectorAll('.produtos .lista-itens')
const filtroTodos = document.getElementById('filtroTodos')

if (filtrosProdutos) {
  filtrosProdutos.forEach(filtro => {

    filtro.addEventListener('click', (e) => {

      if (filtro.querySelector('.btn-filtro').id === 'filtroTodos') {

        filtro.querySelector('.btn-filtro').classList.add('active')

        tipoMarcas.forEach(tipo => {
          tipo.classList.add('active')
        })

        for (i = 0; i < filtrosProdutos.length; i++) {
          if (filtrosProdutos[i].querySelector('.btn-filtro').classList.contains('active'))
            filtrosProdutos[i].querySelector('.btn-filtro').classList.remove('active')
        }

        filtroTodos.classList.add('active')

      } else if (filtro.querySelector('.btn-filtro').classList.contains('active')) {

        filtro.querySelector('.btn-filtro').classList.remove('active')

        tipoMarcas.forEach(tipo => {
          if (!tipo.classList.contains('active')) {
            tipo.classList.add('active')
          }
        })

        filtroTodos.classList.add('active')

      } else {

        for (i = 0; i < filtrosProdutos.length; i++) {
          if (filtrosProdutos[i].querySelector('.btn-filtro').classList.contains('active'))
            filtrosProdutos[i].querySelector('.btn-filtro').classList.remove('active')
        }

        if (filtro.querySelector('.btn-filtro').classList.contains('active')) {
          filtro.querySelector('.btn-filtro').classList.remove('active')
        } else {
          filtro.querySelector('.btn-filtro').classList.add('active')
        }

        tipoMarcas.forEach(tipo => {
          if (tipo.dataset.productType === filtro.querySelector('.btn-filtro').id) {
            tipo.classList.add('active')
          } else {
            tipo.classList.remove('active')
          }
        })
      }

    })

  })
}

// FILTRO ONDE ENCONTRAR

const filtrosPos = document.querySelectorAll('.ondeencontrar__topo-filtro')
const cardsPos = document.querySelectorAll('.ondeencontrar .lista-itens')

if (filtrosPos) {
  filtrosPos.forEach(filtro => {

    filtro.addEventListener('click', (e) => {

      if (filtro.querySelector('.btn-filtro').id === 'filtroTodos') {

        filtro.querySelector('.btn-filtro').classList.add('active')

        cardsPos.forEach(card => {
          card.classList.add('active')
        })

        for (i = 0; i < filtrosPos.length; i++) {
          if (filtrosPos[i].querySelector('.btn-filtro').classList.contains('active'))
            filtrosPos[i].querySelector('.btn-filtro').classList.remove('active')
        }

        filtroTodos.classList.add('active')

      } else if (filtro.querySelector('.btn-filtro').classList.contains('active')) {

        filtro.querySelector('.btn-filtro').classList.remove('active')

        cardsPos.forEach(card => {
          if (!card.classList.contains('active')) {
            card.classList.add('active')
          }
        })

        filtroTodos.classList.add('active')

      } else {

        for (i = 0; i < filtrosPos.length; i++) {
          if (filtrosPos[i].querySelector('.btn-filtro').classList.contains('active'))
            filtrosPos[i].querySelector('.btn-filtro').classList.remove('active')
        }

        if (filtro.querySelector('.btn-filtro').classList.contains('active')) {
          filtro.querySelector('.btn-filtro').classList.remove('active')
        } else {
          filtro.querySelector('.btn-filtro').classList.add('active')
        }

        cardsPos.forEach(card => {
          if (card.dataset.pos === filtro.querySelector('.btn-filtro').id) {
            card.classList.add('active')
          } else {
            card.classList.remove('active')
          }
        })
      }

    })

  })
}

// BOTÕES SAIBA MAIS ONDE ENCONTRAR

const cardsOndeEncontrar = document.querySelectorAll('.ondeencontrar-info')
const infosOndeEncontrar = document.querySelectorAll('.ondeencontrar-background')

if (cardsOndeEncontrar) {
  cardsOndeEncontrar.forEach((cardOndeEncontrar, i) => {
    cardOndeEncontrar.addEventListener('mouseenter', () => {
      console.log('enter')
      for (i = 0; i < cardsOndeEncontrar.length; i++) {
        if (infosOndeEncontrar[i].classList.contains('active')) {
          infosOndeEncontrar[i].classList.remove('active')
        }
      }
      cardOndeEncontrar.nextElementSibling.classList.add('active')
    })
  })
  infosOndeEncontrar.forEach((infoOndeEncontrar, i) => {
    infoOndeEncontrar.addEventListener('mouseleave', () => {
      infoOndeEncontrar.classList.remove('active')
    })
  })
}

// SAIBA MAIS PRODUTOS

const cardsProdutos = document.querySelectorAll('.img-produto')
const infoProdutos = document.querySelectorAll('.info-produto')

if (cardsProdutos) {
  cardsProdutos.forEach((cardProduto, i) => {
    cardProduto.addEventListener('mouseenter', () => {
      for (i = 0; i < cardsProdutos.length; i++) {
        if (infoProdutos[i].classList.contains('active')) {
          infoProdutos[i].classList.remove('active')
        }
      }
      cardProduto.nextElementSibling.classList.add('active')
    })
  })
  infoProdutos.forEach((infoProduto, i) => {
    infoProduto.addEventListener('mouseleave', () => {
      infoProduto.classList.remove('active')
    })
  })
}


// MÁSCARA DE TELEFONE

if (document.getElementById('phone')) {
  document.getElementById('phone').addEventListener('input', function (e) {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  });
}

// TROCAS HOME

if (document.querySelector('.home')) {
  const btnParceiro = document.getElementById('parceiro')
  const btnConsumidor = document.getElementById('consumidor')

  btnParceiro.addEventListener('mouseover', () => {
    document.querySelector('.home').classList.add('parceiro')
    document.querySelector('.home').classList.remove('consumidor')
  })
  btnConsumidor.addEventListener('mouseover', () => {
    document.querySelector('.home').classList.add('consumidor')
    document.querySelector('.home').classList.remove('parceiro')
  })
}

// BTN FLUTUANTE 

const btnParceiro = document.getElementById('btn-parceiro')

if (btnParceiro) {
  var scrollShow = function () {
    var y = window.scrollY
    if (y >= 200) {
      btnParceiro.classList.add('active')
    } else {
      btnParceiro.classList.remove('active')
    }
  }

  // window.addEventListener('scroll', scrollShow)


  document.onscroll = function () {
    if ((window.innerHeight + window.scrollY > document.body.clientHeight - 200) || window.scrollY < 200)
      btnParceiro.classList.remove('active')
    else
      btnParceiro.classList.add('active')
  }
}

